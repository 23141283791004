.Contact {
    min-height: 100%;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background-image: url("../images/contact_background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.Contact-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    font-family: 'Roboto Slab', serif;
    color: white;
}

@media (min-width: 650px ) {
    .Contact-content {
        align-items: flex-end;
        justify-content: center;
    }
}
