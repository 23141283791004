.Skills::before {
    content: "";
    min-height: 100%;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background-image: url("../images/TamCocBackground.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    filter: brightness(0.8); /* Adjust the value as needed */
    z-index: -1;
}
