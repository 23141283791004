.topRegionStyle {
	height: 5vh;
	background-color: transparent;
}

.skillsContainer {
	display: flex;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.8);
	height: 37.5vh;
}

.textContainer {
	flex: 1; /* Takes up the other half of the container width */
	padding: 10px; /* Adjust as needed for spacing */
	color: white;
	text-align: center;
}

.textContainer h2 {
	font-size: 16px; /* Adjust as needed */
	margin-bottom: 10px; /* Adjust as needed for spacing */
}

.textContainer p {
	font-size: 12px; /* Adjust as needed */
	margin-bottom: 10px; /* Adjust as needed for spacing */
}

.textContainer ul {
	list-style-type: disc; /* You can use 'disc' or 'circle' for bullet points */
}

.textContainer li {
	font-size: 12px; /* Adjust as needed */
	margin-bottom: 5px; /* Adjust as needed for spacing between bullet points */
}

.centered-bullets {
	list-style: none; /* Remove default bullet points */
	padding-left: 0; /* Remove default left padding for the list */
	margin-left: 1em;
}

.centered-bullets li::before {
    content: "\2022"; /* Unicode character for a bullet point (•) */
    color: #000; /* Change the color of the bullet point */
    display: inline-block; /* Make it inline-block to center it */
    width: 1em; /* Adjust the width to control spacing */
    margin-left: -1em; /* Offset the bullet point to center it */
}
