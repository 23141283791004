.topRegionStyle {
	height: 5vh;
	background-color: transparent;
}

.caseStudyContainer {
	display: flex;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.8);
	height: 37.5vh;
}

.imageContainer {
	flex: 1; /* Takes up half of the container width */
	padding: 10px; /* Adjust as needed for spacing */
}

.imageStyle {
	max-width: 100%;
	height: auto;
	display: block; /* Ensures image behaves as a block element */
	margin-left: auto;
	margin-right: auto;
}

.textContainer {
	flex: 1; /* Takes up the other half of the container width */
	padding: 10px; /* Adjust as needed for spacing */
	color: white;
	text-align: center;
}

.textContainer h2 {
	font-size: 16px; /* Adjust as needed */
	margin-bottom: 10px; /* Adjust as needed for spacing */
}

.textContainer p {
	font-size: 12px; /* Adjust as needed */
	margin-bottom: 10px; /* Adjust as needed for spacing */
}

.textContainer ul {
	list-style-type: disc; /* You can use 'disc' or 'circle' for bullet points */
}

.textContainer li {
	font-size: 12px; /* Adjust as needed */
	margin-bottom: 5px; /* Adjust as needed for spacing between bullet points */
}